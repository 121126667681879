* {
    margin: 0;
  }

.stars__bg {
  background-image: url("./assets/starsBG.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

.gradient__font {
  background-image: linear-gradient(
    to right,
    #cb9b51 0%, 
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 100%,
    #75430f 100%
    );
   color:transparent;
   background-clip:text;
   font-family: var(--font-family);
   text-transform: uppercase;
}

.button__lg button {
  background: linear-gradient(#f6e27a, rgba(0, 0, 0, 0));
  border: 3px solid white;
  color: white;
  padding: 20px;
  font-family: var(--font-family);
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}

.button__lg button:hover {
  background: linear-gradient(#cb9b51, rgba(0, 0, 0, 0));
  cursor: pointer;
  transform: translate(0px, px);
  transition: all 0.5s ease-in-out;
}

.button__lg button:disabled {
  background: black;
  border: 3px solid rgb(94, 94, 94);
  color: rgb(93, 93, 93);
  cursor: not-allowed;
}

@media screen and (max-width: 500px) {
  .button__lg button {
    border: 3px solid white;
    padding: 8px;
    font-size: 18px;
  }  
}