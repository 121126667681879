.login__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2rem;
}

.login__songTitle {
    letter-spacing: 2px;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    line-height: 1;
}

.login__awardsVoting {
    letter-spacing: 2px;
    font-size: 200px;
    text-align: center;
    font-weight: 100;
    line-height: 1;
}

.login__connectDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.login__connectDetails p {
    color: white;
    font-family: var(--font-family);
    text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;
}

.login__connectDetails img {
    width: 22px;
    padding-right: 15px;
}

@media screen and (max-width: 500px) {    
    .login__main {
        padding: .5rem;
    }

    .login__songTitle {
        letter-spacing: 2px;
        font-size: 30px;
    }
    
    .login__awardsVoting {
        padding-top: 15px;
        font-size: 70px;
    }
    
    .login__connectDetails {
        padding: 50px;
    }
    
    .login__connectDetails p {
        margin-bottom: 50px;
    }
    
    .login__connectDetails img {
        width: 20px;
    }
}