.voting__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.voting__container {
    max-width: 100%;
}

.voting__header {
    padding: 2rem;
}

.voting__songTitle {
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.voting__video iframe{
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #cb9b51, #f6f2c0) border-box;
    border: 5px solid transparent;
}

.voting__header p{
    color: white;
    font-family: var(--font-family);
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    padding: 2rem;
}

.voting__header p2{
    color: white;
    font-family: var(--font-family);
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    padding: 2rem;
}

.voting__entry {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.voting__entry-img {
    padding: 2rem;
}

.voting__entry-img img{
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, #cb9b51, #f6f2c0) border-box;
    border: 5px solid transparent;
    width: 600px;
}

.voting__entry-txt h2{
    font-weight: 500;
}

.voting__entry-txt input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 3px solid #cb9b51;
  border-radius: 0%;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  transition: all 0.15s ease-in-out;
}

.voting__entry-txt input[type="radio"]:checked {
    background-color: #ffffff;
}

.voting__submit {
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
}

.voting__thanks {
    max-width: 100%;
}

.voting__thanks h1{
    padding-top: 40px;
    letter-spacing: 2px;
    font-size: 100px;
    text-align: center;
    font-weight: 700;
    line-height: 1;
}

.voting__thanks h2{
    font-size: 26px;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 20px;
}

.voting__thanks-award{
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.voting__thanks-award img{
    max-width: 200px;
}

.voting__thanks ul {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .voting__thanks ul li {
    display: inline-block;
    margin: 20px;
  }

@media screen and (max-width: 500px) {
    .voting__songTitle {
        font-size: 30px;
    }
    
    .voting__video iframe{
        background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #cb9b51, #f6f2c0) border-box;
        border: 5px solid transparent;
        max-width: 100%;
        height: 200px;
    }
    
    .voting__header {
        padding: 1rem;
    }

    .voting__header p{
        font-size: 15px;
    }
    
    .voting__entry {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .voting__entry-img {
        padding: 2rem;
    }
    
    .voting__entry-img img{
        background: linear-gradient(white, white) padding-box,
                    linear-gradient(to right, #cb9b51, #f6f2c0) border-box;
        border: 5px solid transparent;
        width: 100%;
    }

    .voting__entry-txt input[type="radio"] {
        margin-top: 10px;
        width: 20px;
        height: 20px;
        margin-bottom: 40px;
      }
    
    .voting__submit {
        padding: 2rem;
    }

    .voting__thanks h1{
        padding-top: 40px;
        letter-spacing: 0;
        font-size: 50px;
    }
    
    .voting__thanks h2{
        font-size: 16px;
    }

    .voting__thanks img{
        width: 100%;
    }
    
    .voting__thanks ul {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
      }
      
      .voting__thanks ul li {
        display: inline-block;
        margin: 25px;
      }
}